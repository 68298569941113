import * as React from 'react';
import commaNumber from 'comma-number';
import { Link } from 'react-router-dom';

import { getPrice, getViews, getClass } from '../utils';

const Table = ({ data }) => {
  return (
    <div className="Table">
      <table>
        <thead>
          <tr className="text-right">
            <th>Apt</th>
            <th>Bed</th>
            <th>Bath</th>
            <th>Car</th>
            <th className="text-left">View</th>
            <th>Floor</th>
            <th>Balcony</th>
            <th>Total</th>
            <th>B/C</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr className="text-right" className={getClass(row.availability)}>
              <td>
                <Link to={`/apartments/${row.id}`}>{row.apt}</Link>
              </td>
              <td>{row.beds}</td>
              <td>{row.baths}</td>
              <td>{row.car}</td>
              <td className="text-left">{getViews(row.views)}</td>
              <td>{row.floor}</td>
              <td>{row.balcony}</td>
              <td>{row.total}</td>
              <td>${commaNumber(row.b_by_c)}</td>
              <td>{getPrice(row)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;

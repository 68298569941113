import { FETCH_DATA_REQUEST, FETCH_DATA_SUCCESS, FETCH_DATA_FAILURE } from './apartmentReducer';
import { firestore } from '../firebase';

export const fetchApartments = () => {
	return async (dispatch) => {
		try {
			dispatch({ type: FETCH_DATA_REQUEST });
			const { docs } = await firestore.collection('apartments').orderBy('apt', 'asc').get();
			const data = docs.map((doc) => ({ id: doc.id, ...doc.data() }));
			dispatch({ type: FETCH_DATA_SUCCESS, payload: data });
		} catch (error) {
			dispatch({ type: FETCH_DATA_FAILURE, payload: error.message });
			alert(error.message);
		}
	};
};

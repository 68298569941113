import * as React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import HomePage from './pages/HomePage';
import ApartmentPage from './pages/ApartmentPage';
import { fetchApartments } from './store/actions';

const App = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchApartments());
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/apartments/:id" component={ApartmentPage} />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;

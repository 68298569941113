import * as React from 'react';

const MainHeader = () => {
  return (
    <div className="MainHeader">
      <header>
        <img src="/images/primary-logo.png" />
      </header>
    </div>
  );
};

export default MainHeader;

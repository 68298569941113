export const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';

const initialState = {
	data: [],
	loading: false,
	error: null,
};

export const apartmentReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_DATA_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
			};

		case FETCH_DATA_SUCCESS:
			return {
				...state,
				data: payload,
				loading: false,
			};

		case FETCH_DATA_FAILURE:
			return {
				...state,
				error: payload,
				loading: false,
			};

		default:
			return state;
	}
};

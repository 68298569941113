import * as React from 'react';
import { Link } from 'react-router-dom';

const SecondaryHeader = () => {
  return (
    <div className="SecondaryHeader">
      <header>
        <Link to="/">
          <img src="/images/secondary-logo.png" />
        </Link>
      </header>
    </div>
  );
};

export default SecondaryHeader;

import commaNumber from 'comma-number';

export const getPrice = ({ availability, price }) =>
  availability === 'sold' ? 'SOLD' : availability === 'under_offer' ? 'UNDER OFFER' : '$' + commaNumber(price);

export const getViews = (views) => {
  if (!views) return;
  const _views = [];

  if (views.north) _views.push('N');
  if (views.south) _views.push('S');
  if (views.east) _views.push('E');
  if (views.west) _views.push('W');

  return _views.join('');
};

export const getClass = (availability) =>
  availability === 'sold' ? 'sold' : availability === 'under_offer' ? 'under-offer' : '';

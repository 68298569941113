import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory, Link } from 'react-router-dom';
import ApartmentDetails from '../components/ApartmentDetails';
import SecondaryHeader from '../components/SecondaryHeader';

const ApartmentPage = () => {
  const { id } = useParams();
  const history = useHistory();
  const { data, loading, error } = useSelector((store) => store.apartment);
  const [apartments, _setApartments] = React.useState([]);
  const [apartment, setApartment] = React.useState(null);

  const apartmentsRef = React.useRef([]);

  const setApartments = (apartments) => {
    apartmentsRef.current = apartments;
    _setApartments(apartment);
  };

  const getApartmentById = (id) => apartmentsRef.current.find((row) => row.id === id);
  const getApartmentBykey = (key) => apartmentsRef.current.find((row) => row.navigationKey === key);

  const handleKeyDown = (e) => {
    const key = String(e.key);
    if (key === 'Shift') return;
    const apartment = getApartmentBykey(key);
    if (apartment) history.push(`/apartments/${apartment.id}`);
  };

  React.useEffect(() => {
    if (!loading) {
      setApartments(data);
      const apartment = getApartmentById(id);
      setApartment(apartment);
    }
  }, [id, loading]);

  React.useEffect(() => {
    document.body.style.color = '#000000';
    document.body.style.backgroundColor = '#ffffff';
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      document.body.style.color = '#ffffff';
      document.body.style.backgroundColor = '#000000';
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <React.Fragment>
      <SecondaryHeader />
      <section style={{ minHeight: 'calc(100vh - 80px)', paddingTop: '2rem' }}>
        <div className="container">
          {loading ? (
            <p className="text-center">Loading...</p>
          ) : error ? (
            <p className="text-center">{error}</p>
          ) : apartment ? (
            <ApartmentDetails data={apartment} />
          ) : (
            <p>
              Can not find the apartment <Link to="/">Go to homepage for the list</Link>
            </p>
          )}
        </div>
      </section>
      <div style={{ height: 100 }}></div>
    </React.Fragment>
  );
};

export default ApartmentPage;

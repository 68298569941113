import * as React from 'react';
import { useSelector } from 'react-redux';
import MainHeader from '../components/MainHeader';
import Table from '../components/Table';

const HomePage = () => {
  const { data, loading, error } = useSelector((store) => store.apartment);

  return (
    <React.Fragment>
      <MainHeader />
      <section>
        <div className="container">
          {loading ? (
            <p className="text-center">Loading...</p>
          ) : error ? (
            <p className="text-center">{error}</p>
          ) : (
            <Table data={data} />
          )}
        </div>
      </section>
      <div style={{ height: 100 }}></div>
    </React.Fragment>
  );
};

export default HomePage;

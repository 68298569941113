import * as React from 'react';

import { getPrice } from '../utils';

const ApartmentDetails = ({ data }) => {
	return (
		<div className="ApartmentDetails">
			<div className="imageContainer">
				<div className="image1">
					<img src={data.image1} />
				</div>
				<div className="image2">
					<img src={data.image2} />
				</div>
			</div>
			<div className="details">
				<ul>
					<li>
						<span className="icon icon-bed"></span>
						<h4>{data.beds} Bedrooms</h4>
					</li>
					<li>
						<span className="icon icon-bath"></span>
						<h4>{data.baths} Bathrooms</h4>
					</li>
					<li>
						<span className="icon icon-car"></span>
						<h4>{data.car} Carpark</h4>
					</li>
				</ul>
				<table>
					<tbody>
						<tr>
							<td>INTERNAL AREA</td>
							<td>
								{data.floor} m <sup>2</sup>
							</td>
						</tr>
						<tr>
							<td>OUTDOOR AREA</td>
							<td>
								{data.balcony} m <sup>2</sup>
							</td>
						</tr>
						<tr>
							<td>
								<strong>Total</strong>
							</td>
							<td>
								<strong>
									{data.total} m <sup>2</sup>
								</strong>
							</td>
						</tr>
					</tbody>
				</table>
				<div className="status">
					<h1>
						{data.apt} <span className="thin">{getPrice(data)}</span>
					</h1>
				</div>
			</div>
		</div>
	);
};

export default ApartmentDetails;
